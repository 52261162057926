import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import { mainColor } from "../../constants/colors";
import { Desktop, Tablet } from "../../constants/responsive";
import { useAppContext } from "../../context/AppContext";
import Check from "../../svgs/check.svg";

const SignUpSuccessPopup = () => {
  const intl = useIntl();
  const {
    signUpSuccess,
    setSignUpSuccess,
    appStoreUrl,
    apiUrl,
  } = useAppContext();
  return (
    <div
      css={css`
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: ${signUpSuccess ? "flex" : "none"};
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9999;
      `}
    >
      <div
        css={css`
          background: ${mainColor};
          border-radius: 12px;
          position: relative;
          display: flex;
          overflow: hidden;
          width: 90%;
          max-width: 340px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          text-align: center;
          padding: 20px;
          height: 300px;
          ${Tablet} {
            max-width: none;
            padding: 20px;
            width: 340px;
          }
          ${Desktop} {
            padding: 40px;
            width: 440px;
            height: 360px;
          }
        `}
      >
        <Check
          css={css`
            width: 36px;
            height: 36px;
            margin-bottom: 20px;
            * {
              fill: white;
            }
            ${Desktop} {
              width: 60px;
              height: 60px;
              margin-bottom: 30px;
            }
          `}
        />
        <h2
          css={css`
            display: block;
            margin-bottom: 16px;
          `}
        >
          {
            {
              en: "Registration Successful",
              "zh-cn": "注册成功",
              "zh-hk": "註冊成功",
            }[intl.locale]
          }
        </h2>
        <h5
          css={css`
            display: block;
            ${Desktop} {
              display: none;
            }
          `}
        >
          {
            {
              en: "Download the AQUMON App now to start investing smart!",
              "zh-cn": "立刻下载APP 开始智能投资",
              "zh-hk": "立即下載APP  開始智能投資",
            }[intl.locale]
          }
        </h5>

        <div
          css={css`
            background: white;
            padding: 6px 16px;
            border-radius: 30px;
            color: ${mainColor};
            font-weight: bold;
            font-size: 12px;
            user-select: none;
            margin-top: 10px;
            cursor: pointer;
            ${Desktop} {
              display: none;
            }
          `}
          onClick={() => {
            // Provided code not working :(
            const openApp = (src) => {
              const ifr = document.createElement("iframe");
              ifr.src = src;
              ifr.style.display = "none";
              document.body.appendChild(ifr);
              window.setTimeout(() => {
                document.body.removeChild(ifr);
              }, 2000);
            };
            const userAgent =
              navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
              if (
                openApp(
                  "aqumon://www.aqumon.com/openwith?key=value&key2=value2"
                )
              ) {
                openApp(
                  "aqumon://www.aqumon.com/openwith?key=value&key2=value2"
                );
              } else {
                setTimeout(() => {
                  window.location.href =
                    "https://oss-static.aqumon.com/download/aqumon/index.html";
                }, 600);
              }
            } else {
              // window.location.href = "https://www.aqumon.com/app/";
              const aLabel = document.createElement("a");
              aLabel.href = "https://www.aqumon.com/app/";
              // 此操作会调起app并阻止接下来的js执行
              aLabel.click();
              setTimeout(() => {
                window.location.href =
                  "https://oss-static.aqumon.com/download/aqumon/index.html";
              }, 600);
            }
            // }
            // 修改为上面代码
            // if (typeof window !== "undefined") {
            //   const userAgent =
            //     navigator.userAgent || navigator.vendor || window.opera;
            //   if (/android/i.test(userAgent)) {
            //     window.location.href = window.apkDownloadUrl;
            //   } else {
            //     window.location.href = appStoreUrl;
            //   }
            // }
          }}
        >
          {
            {
              en: "Download APP",
              "zh-cn": "下载APP",
              "zh-hk": "下載APP",
            }[intl.locale]
          }
        </div>

        <a
          // href={`https://smarthk.aqumon.com/`}
          href={`${apiUrl}smarthk.aqumon.com/`}
          css={css`
            background: white;
            padding: 6px 16px;
            border-radius: 30px;
            color: ${mainColor} !important;
            font-weight: bold;
            font-size: 12px;
            user-select: none;
            display: none;
            ${Desktop} {
              display: block;
              font-size: 20px;
              padding: 10px 30px;
              margin-top: 40px;
            }
          `}
        >
          {
            {
              en: "Start Investing",
              "zh-cn": "开始投资",
              "zh-hk": "開始投資",
            }[intl.locale]
          }
        </a>

        <div
          css={css`
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          onClick={() => {
            setSignUpSuccess(false);
          }}
        >
          {[1, -1].map((i) => {
            return (
              <div
                key={i}
                css={css`
                  background: white;
                  width: 30px;
                  height: 2px;
                  position: absolute;
                  transform: ${`rotate(${i * 45}deg)`};
                `}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccessPopup;
